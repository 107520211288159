<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
    }" :visible="visible" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Email incorreto</h5>
        </template>

        <!-- <Loader v-if="isLoading" /> -->
        <div class="content">
          <p class="change-number">Informe o e-mail correto</p>

          <div class="inputs">
            <InputText v-model="email" :class="errorMessage && 'p-invalid'" placeholder="Email" @keyup.enter="submit" />
            <p class="p-invalid" v-if="errorMessage">{{ errorMessage }}</p>
            <!-- END INPUT TO MASK NUMBER -->
            <div class="flex justify-content-center align-items-center h-full mt-24">
                <Button label="Atualizar" class="addService-button" @click="submit" />
            </div>
          </div>
        </div>
    </Dialog>
</template>
  
<script>
import { ref, onMounted, watch, reactive, computed } from "vue";
import{ UpdateEmail, validateEmail } from "./actions";

export default {
    props: ["visible",],
    emits: ["update:visible", "update-email"],
    components: {
    },
    setup(props, ctx) {
        const mounted = ref(false);
        const email = ref("");
        const email_used = ref("");
        const errorMessage = ref("");

        const close = () => {
            ctx.emit("update:visible", false);
            clear();
        }

        const onUpdate = (val) => {
            (!val) && ctx.emit("update:visible", val);
        }

        const submit = async () => {
            if(validateEmail(email.value)){
                const res = await UpdateEmail(email.value)
                if(res.success){
                    ctx.emit("update-email", email.value);
                    errorMessage.value = await "";
                    close();
                }else{
                    email_used.value = await email.value;
                    errorMessage.value = await res.errormessage;
                }             
            }      
        }

        const clear = () => {
            email.value = "";
            errorMessage.value = "";
            mounted.value = false;
        }


        const updateModel = (e) => {
            console.log(e);
        }
        
        onMounted(async () => {
            mounted.value = await true;
        });

        return {
            close,
            onUpdate,
            submit,
            email,
            updateModel,
            errorMessage,
        };
    },
};
</script>
  
<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 1.5rem;
    .change-number {
        font-size: 14px;
        line-height: 22px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: #828282;
        max-width: 40ch;
    }
    .inputs{
        display: flex;
        flex-direction: column;
    }
    .p-invalid{
        margin-top: 4px;
        margin-left: 6px;
    }
}

::v-deep(input) {
    height: 50px;
    padding: 0 16px;
    border-radius: 8px;
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    &:disabled{
        background: white;
        /* opacity: 1; */
        cursor: not-allowed !important;
    }
    &::placeholder{
        color: #BDBDBD;       
    }
}
::v-deep(button) {
    width: max-content;
    height: 40px;
    padding: 0 80px;
}
</style>