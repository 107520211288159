<template>
    <div class="email-confirm">
        <div class="header">
            <h1>Confirmação de Email</h1>
        </div>

        <div class="limit">
            <p class="text-code mt-20">Enviamos um link de confirmação para</p>
            <p class="text-code" :style="{maxWidth: '44ch'}">{{email}}</p>
            <p class="text-code">Ele pode demorar um pouco para chegar.</p>


            <p class="email-incorrect mt-24" @click="visibleChangeEmail = !visibleChangeEmail">Meu e-mail está incorreto</p>

            <div class="flex w-full justify-content-center mt-2">
                <Button class="btn-submit btn-outline-primary" label="Não recebeu?" @click="reSend" />
            </div>
        </div>
        <DialogChangeEmail v-model:visible="visibleChangeEmail" @update-email="updateEmail" />
        <Toast />
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import DialogChangeEmail from "./DialogChangeEmail"
import { SendEmailConfirmation, validateEmail, UpdateEmail } from "./actions"
import { useToast } from "primevue/usetoast";


const toast = useToast();
let router = useRouter();
const email = ref("");
const visibleChangeEmail = ref(false);

const reSend = async () => {
    if(validateEmail(email.value)){
        SendEmailConfirmation(email.value);
        toast.add({
            severity: "success", 
            summary: "Email enviado", 
            detail: "Enviamos um novo email de confirmação para você.", 
            life: 3000, 
            closable: true 
        });
    }
};

const updateEmail = mail => {
    if(mail){
        localStorage.setItem("email", mail);
        email.value = mail;
        router.push("/account/pending/confirmation");
    }
}

onMounted(() => {
    const emailLS = localStorage.getItem("email");
    if(emailLS){
        email.value = emailLS;
    }
})        
</script>


<style lang="scss" scoped>
.email-confirm {
    display: flex;
    flex-direction: column;
    width: 478px;
    height: max-content;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;

    .center{
        display: block;
        margin: auto;
        margin-right: auto;
    }

    .btn-submit{
        margin-top: 20px;
    }


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header {
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;

        h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }

    .limit {
        width: 100% !important;
        padding: 0 32px;

        .text-code {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
        }
        .email-incorrect{
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #2F80ED;
            text-align: center;
            cursor: pointer;
            margin: 0 auto;
        }
    }
}

@media (max-width: 580px) {
    .form {
        width: 100%;
    }
}

:deep(){
    .p-button {
        margin-top: 10px;
        padding: 20px 38px;
        width: max-content;
    }
    input {
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        font-family: Roboto, sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        &:disabled{
            background: white;
            /* opacity: 1; */
            cursor: not-allowed !important;
        }
        &::placeholder{
            color: #BDBDBD;       
        }
    }
    .birthday-form{
        border-radius: 8px;
    }
    .p-inputmask{
        text-align: center;
        letter-spacing: 3px;
        font-size: 20px;
    }
    button {
        background: white !important;

        &:hover {
            background: linear-gradient(to bottom, #2F80ED, #4A90E2) !important;
            border-color: #2F80ED !important;
        }
    }
}
</style>