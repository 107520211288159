import patientAcountService from "../../../core/services/patientAcountService";
import { 
    SendEmailConfirmationRequest,
    UpdateEmailRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const SendEmailConfirmation = async (email) => {
    try {
        const req = new SendEmailConfirmationRequest();
        req.setEmail(email);
        const res = await patientAcountService.sendEmailConfirmation(req);
        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const UpdateEmail = async (email) => {
    try {
        const req = new UpdateEmailRequest();
        req.setEmail(email);
        const res = await patientAcountService.updateEmail(req);
        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
};

const validateEmail = email => {
    var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return re.test(email);
}

export {
    SendEmailConfirmation,
    UpdateEmail,
    validateEmail,
}