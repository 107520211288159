<template>
  <div class="email-confirmation">
    <div class="limit">
      <router-link to="/account/login">
        <img src="../../../../assets/images/ommed_logo.svg" class="logo" />
      </router-link>
      <div class="container-form">
        <Form />
      </div>
    </div>
  </div>
</template>
    
  <script>
  import { ref, computed, onMounted } from "vue";
  import { useRouter } from "vue-router";
  import Form from "./Form"
  
  export default {
    components: {
      Form
    },
    setup() {
      const router = useRouter();  
  
      onMounted(() => {
      })
  
      return {
  
      };
    },
  };
  </script>
    
  <style lang="scss" scoped>
  .email-confirmation {
    display: flex;
    flex-direction: column;
    background: #F2F2F2;
    max-width: 100%;
    min-height: 100vh;
  
    .limit {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: max-content;
      padding: 38px;
      min-height: 100vh;
      transform: translateY(-40px);
      a{
        width: max-content;
        padding: 0;
      }
      .logo{
        padding: 0;
      }
    }
  }
  
  
  @media (max-width: 1399px) {
    .email-confirmation {
      .limit {
        width: 100%;
      }
    }
  }
  
  @media (min-width: 1420px) {
    .email-confirmation {
      .limit {
        width: 1400px;
      }
    }
  }
  </style>
    